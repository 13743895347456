<template>
  <div class="home">
    <SlideShow />

    <MenuSolucoes />

    <!-- SERVIÇOS -->
    <section id="home-servicos">
      <div class="container">
        <p class="center text-high" data-aos="fade-up" data-aos-duration="600">
          <span class="text-lg"
            >Soluções que <b>integram funcionalidades</b>, otimizam a
            performance da sua instituição e promovem a entrega de
            <b>serviços de saúde com mais qualidade.</b></span
          >
        </p>
        <div class="servicos-content">
          <ul>
            <li data-aos="fade-up" data-aos-duration="200">
              <span class="servicos-content-item">
                <span class="ico-servicos ico-gestao-projetos"></span>
                <span>Gestão de Projetos</span>
              </span>
            </li>
            <li data-aos="fade-up" data-aos-duration="400">
              <span class="servicos-content-item">
                <span class="ico-servicos ico-consultoria"></span>
                <span>Consultoria</span>
              </span>
            </li>
            <li data-aos="fade-up" data-aos-duration="600">
              <span class="servicos-content-item">
                <span class="ico-servicos ico-service-desk"></span>
                <span>Service Desk</span>
              </span>
            </li>
            <li data-aos="fade-up" data-aos-duration="800">
              <span class="servicos-content-item">
                <span class="ico-servicos ico-automacao"></span>
                <span>Automação</span>
              </span>
            </li>
            <li data-aos="fade-up" data-aos-duration="1000">
              <span class="servicos-content-item">
                <span class="ico-servicos ico-portal-compras"></span>
                <span>Portal de Compras</span>
              </span>
            </li>
            <li data-aos="fade-up" data-aos-duration="1200">
              <span class="servicos-content-item">
                <span class="ico-servicos ico-sms"></span>
                <span>SMS</span>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </section>

    <!-- PRECISA DE UM ORÇAMENTO -->
    <section id="home-precisa-de-um-orcamento">
      <div class="container pad-lg pad-lg-m">
        <p class="center text-high" data-aos="fade-up">
          <span class="text-xlg white"
            >Precisa de um <b>orçamento</b> de acordo com as
            <b>necessidades da sua instituição de saúde?</b></span
          >
        </p>
      </div>
    </section>

    <!-- ORÇAMENTO -->
    <OrcamentoModulos />

    <!-- QUEM SOMOS -->
    <section id="home-quem-somos">
      <div class="container">
        <i class="logo-sticky" />
        <div class="row">
          <div class="col col-2-4">
            <h5 class="gray" data-aos="fade-up" data-aos-duration="300">
              Quem somos
            </h5>
            <h2 data-aos="fade-up" data-aos-duration="600">Nossa História</h2>
            <p class="pad-top-md" data-aos="fade-up" data-aos-duration="900">
              Criada em 1988, a <b>SPDATA</b> é 100% nacional, sendo uma das
              maiores empresas de tecnologia para a Saúde do país. Temos como
              propósito, o desenvolvimento do setor saúde através da tecnologia
              e inovação...
            </p>
            <div
              class="btn-container"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <router-link to="/sobre" class="btn btn-lg btn-sec"
                >Saiba mais</router-link
              >
            </div>
          </div>
          <div class="col col-2-4 right">
            <iframe
              rel="preload"
              data-aos="fade-up"
              data-aos-duration="1200"
              width="90%"
              height="300px"
              frameborder="0"
              src="https://cdn.spdata.com.br/videos/institucional.mp4"
            >
            </iframe>
          </div>
        </div>
      </div>
    </section>

    <!-- NOTICIAS -->
    <!-- <section id="home-noticias">
      <div class="container">
        <h5 class="center" data-aos="fade-up">Últimas</h5>
        <h3 class="center" data-aos="fade-up">Notícias</h3>
        <div class="row pad-top-md">
          <div class="col col-1-3" data-aos="fade-up" data-aos-duration="400">
            <a
              href="http://34.234.37.45/2020/05/07/solucoes-desenvolvidas-pela-spdata-para-otimizacao-dos-hospitais-no-combate-ao-covid-19/"
              target="_blank"
            >
              <div class="noticia">
                <div class="noticia-img">
                  <img src="../../src/assets/images/bg-cta.webp" />
                </div>
                <div class="noticia-tag">
                  <span>Gestão Estratégica</span>
                  <span>Hospitalar</span>
                </div>
                <div class="noticia-titulo">
                  <h5>
                    Soluções desenvolvidas pela SPDATA para otimização dos
                    hospitais no combate ao Covid-19
                  </h5>
                </div>
                <div class="noticia-info">
                  <span class="noticia-info-autor">SPDATA</span>
                  <span class="noticia-info-data">29/04/2019</span>
                </div>
                <div class="noticia-descricao">
                  <p>
                    De acordo com nosso analista de projetos do nosso ERP Marcus
                    Barbosa Soares, as soluções desenvolvidas pela Spdata ...
                  </p>
                </div>
              </div>
            </a>
          </div>
          <div class="col col-1-3" data-aos="fade-up" data-aos-duration="800">
            <a
              href="http://34.234.37.45/2019/04/29/a-filantropia-e-o-cebas/"
              target="_blank"
            >
              <div class="noticia">
                <div class="noticia-img">
                  <img src="../../src/assets/images/clinicas.webp" />
                </div>
                <div class="noticia-tag">
                  <span>Saúde Pública</span>
                </div>
                <div class="noticia-titulo">
                  <h5>A filantropia e o CEBAS</h5>
                </div>
                <div class="noticia-info">
                  <span class="noticia-info-autor">SPDATA</span>
                  <span class="noticia-info-data">03/04/2019</span>
                </div>
                <div class="noticia-descricao">
                  <p>
                    Originalmente, filantropia deriva do termo grego
                    philanthropia, que pode ser traduzido como “amor ao homem”
                    ou “amor à humanidade” ...
                  </p>
                </div>
              </div>
            </a>
          </div>
          <div class="col col-1-3" data-aos="fade-up" data-aos-duration="1200">
            <a
              href="http://34.234.37.45/2019/04/03/prontuario-eletronico-a-spdata-tem-sistema-necessario-para-dar-mais-seguranca-aos-hospitais-e-pacientes/"
              target="_blank"
            >
              <div class="noticia">
                <div class="noticia-img">
                  <img src="../../src/assets/images/hospitais.webp" />
                </div>
                <div class="noticia-tag">
                  <span>TI e Saúde</span>
                </div>
                <div class="noticia-titulo">
                  <h5>
                    Prontuário eletrônico: a SPDATA tem sistema necessário para
                    dar mais segurança aos hospitais e pacientes.
                  </h5>
                </div>
                <div class="noticia-info">
                  <span class="noticia-info-autor">SPDATA</span>
                  <span class="noticia-info-data">01/04/2020</span>
                </div>
                <div class="noticia-descricao">
                  <p>
                    Ainda que os dados constantes no prontuário sejam do
                    paciente, o dever de guarda e manutenção é da instituição de
                    saúde ...
                  </p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section> -->

    <!-- CASES
    <section id="home-cases">
      <div class="container">
        <h5 class="center" data-aos="fade-up">Casos de sucesso</h5>
        <h3 class="center" data-aos="fade-up">O que dizem os clientes</h3>
        <div class="box-cards">
          <div class="row pad-top-md">
            <div class="col col-1-3">
              <div
                class="cases-card"
                data-aos="fade-up"
                data-aos-duration="400"
              >
                <p class="cases-depoimento">
                  <i
                    >O Software de Gestão Hospitalar da SPDATA é um ferramenta
                    indispensável para gerir/administrar uma instituição de
                    saúde com foco voltado para os processos institucionais.
                    Outro ponto forte da empresa é o SAC que é nota 10.</i
                  >
                </p>

                <div class="cases-user">

                  <div class="cases-user-img">
                    <img
                      src="../../src/assets/images/foto-cliente-1.webp"
                      class="cases-foto"
                      alt="foto-cliente"
                    />
                  </div>

                  <div class="cases-user-desc left">
                    <p class="cases-cliente">Welisson Geraldo dos Reis</p>
                    <p class="cases-instituicao">
                      Hospital Nossa Senhora das Dores
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col col-1-3">
              <div
                class="cases-card"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                <p class="cases-depoimento">
                  <i
                    >É bastante interessante participar do curso de capacitação,
                    pois para mim o sistema é novo, haja vista, que assumi
                    recentemente o cargo de gestora de TI, com certeza é um
                    grande investimento profissional.</i
                  >
                </p>
                <div class="cases-user">

                  <div class="cases-user-img">
                    <img
                      src="../../src/assets/images/foto-cliente-1.webp"
                      class="cases-foto"
                      alt="foto-cliente"
                    />
                  </div>

                  <div class="cases-user-desc left">
                    <p class="cases-cliente">Naiara Cristina Moraes</p>
                    <p class="cases-instituicao">Santa Casa de Paraíso</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col col-1-3">
              <div
                class="cases-card"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <p class="cases-depoimento">
                  <i
                    >Durante o curso de Faturamento de Convênios – Momento em
                    que a SPDATA “posiciona e capacita” seus clientes com
                    ferramentas e subsídios tecnológicos para garantir a
                    eficácia na Gestão Hospitalar.</i
                  >
                </p>
                <div class="cases-user">

                  <div class="cases-user-img">
                    <img
                      src="../../src/assets/images/foto-cliente-1.webp"
                      class="cases-foto"
                      alt="foto-cliente"
                    />
                  </div>

                  <div class="cases-user-desc left">
                    <p class="cases-cliente">Jossimar Coimbra Almeida</p>
                    <p class="cases-instituicao">
                      Hospital Rio Doce - Linhares-ES
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    -->

    <!-- PARCEIROS
    <section id="home-parceiros">
      <div class="container">
        <h4 class="center" data-aos="fade-up">Nossos parceiros</h4>
        <div class="box-logo-parceiros">
          <div class="row pad-top-sm">
            <div
              class="col col-1-6 center"
              data-aos="fade-up"
              data-aos-duration="200"
            >
              <img
                src="../../src/assets/images/logo-amazon.png"
                class="parceiros-logo"
                alt="Amazon"
                title="Amazon"
              />
            </div>
            <div
              class="col col-1-6 center"
              data-aos="fade-up"
              data-aos-duration="400"
            >
              <img
                src="../../src/assets/images/logo-cliente-first.png"
                class="parceiros-logo"
                alt="First"
                title="First"
              />
            </div>
            <div
              class="col col-1-6 center"
              data-aos="fade-up"
              data-aos-duration="600"
            >
              <img
                src="../../src/assets/images/logo-safeweb.png"
                class="parceiros-logo"
                alt="SafeWeb"
                title="SafeWeb"
              />
            </div>
            <div
              class="col col-1-6 center"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <img
                src="../../src/assets/images/logo-mevo.png"
                class="parceiros-logo logo-mevo"
                alt="Mevo Data"
                title="Mevo Data"
              />
            </div>
            <div
              class="col col-1-6 center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <img
                src="../../src/assets/images/logo-cliente-genus.webp"
                class="parceiros-logo"
                alt="Genus"
                title="Genus Tecnologia"
              />
            </div>
            <div
              class="col col-1-6 center"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <img
                src="../../src/assets/images/logo-veltio.webp"
                class="parceiros-logo"
                alt="Veltio"
                title="Veltio Tecnologia"
              />
            </div>
          </div>

          <div class="row pad-top-sm">
            <div
              class="col col-1-6 center"
              data-aos="fade-up"
              data-aos-duration="200"
            >
              <img
                src="../../src/assets/images/logo-am2saude.png"
                class="parceiros-logo"
                alt="AM2 Saúde"
                title="AM2 Saúde"
              />
            </div>
            <div
              class="col col-1-6 center"
              data-aos="fade-up"
              data-aos-duration="400"
            >
              <img
                src="../../src/assets/images/logo-cliente-federas.png"
                class="parceiros-logo"
                alt="Federas"
                title="Federas Santas"
              />
            </div>
            <div
              class="col col-1-6 center"
              data-aos="fade-up"
              data-aos-duration="600"
            >
              <img
                src="../../src/assets/images/logo-mvm.webp"
                class="parceiros-logo"
                alt="MVM"
                title="MVM - Medical Virtual Market"
              />
            </div>
            <div
              class="col col-1-6 center"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <img
                src="../../src/assets/images/logo-gostartlab.webp"
                class="parceiros-logo"
                alt="GoStartLab"
                title="GO STARTLAB"
              />
            </div>
            <div
              class="col col-1-6 center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <img
                src="../../src/assets/images/logo-safe2pay.webp"
                class="parceiros-logo"
                alt="Safe2Pay"
                title="Safe 2 Pay"
              />
            </div>
            <div
              class="col col-1-6 center"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <img
                src="../../src/assets/images/logo-grupossit.webp"
                class="parceiros-logo"
                alt="GrupoSSIT"
                title="Grupo SSIT"
              />
            </div>
          </div>
          <div class="row pad-top-sm">
            <div
              class="col col-1-6 center"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <img
                src="../../src/assets/images/Nova Logo HDPO.png"
                class="parceiros-logo"
                alt="HDPO"
                title="HDPO"
                style="max-height: 45px;"
              />
            </div>
            <div
              class="col col-1-6 center"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <img
                src="../../src/assets/images/Logo Protegon - Amarelo com Preto - Fundo Vazado.png"
                class="parceiros-logo"
                alt="Protegon"
                title="Protegon"
                style="max-height: 45px;"
              />
            </div>
          </div>
        </div>
      </div>
    </section>  -->

    <!--<a
      href="https://wa.me/5531992926945"
      target="_blank"
      class="btn-float-whatsapp-home heartbeat"
    >
      <span></span>
    </a>-->
  </div>
</template>


<script>
import SlideShow from "@/components/SlideShow.vue";
import OrcamentoModulos from "@/components/OrcamentoModulos.vue";
import MenuSolucoes from "@/components/MenuSolucoes.vue";
export default {
  name: "Home",
  components: {
    SlideShow,
    OrcamentoModulos,
    MenuSolucoes,
  },
};
</script>
